/*
 *
 * FavoritePage actions
 *
 */

import {} from './types';

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const applySearch = (data: any) => action(ActionTypes.APPLY_SEARCH, data);
export const fetchMore = () => action(ActionTypes.FETCH_MORE_FAVORITES);

export const favorite = createAsyncAction(
  ActionTypes.FETCH_FAVORITE_REQUEST,
  ActionTypes.FETCH_FAVORITE_SUCCESS,
  ActionTypes.FETCH_FAVORITE_FAILURE,
)<{}, {}, Error>();

export const suppliers = createAsyncAction(
  ActionTypes.FETCH_SUPPLIERS_REQUEST,
  ActionTypes.FETCH_SUPPLIERS_SUCCESS,
  ActionTypes.FETCH_SUPPLIERS_FAILURE,
)<void, {}, Error>();
