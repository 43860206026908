import * as qs from 'utils/queryString';

import { call, fork, put, select, take } from 'redux-saga/effects';
import { favorite as favoriteActions } from './actions';
import { getProducts } from 'utils/apollo';
import { selectScrollInfo } from './selectors';

import ActionTypes from './constants';
import { makeSelectLocation } from 'containers/MainLayout/selectors';
import { push } from 'connected-react-router';

function* searchFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_SEARCH);
    const location = yield select(makeSelectLocation());
    const oldQuery = location.search.length > 0 ? location.search.slice(1) : '';
    const newQuery = qs.stringify(payload);

    if (newQuery === oldQuery) {
      continue;
    }

    yield put(
      push({
        search: qs.stringify(payload),
      }),
    );
  }
}

function* initData() {
  const location = yield select(makeSelectLocation());
  const defaultSearch = {
    sort: [],
    filter: {
      supplierNames: [],
      categoryName: '',
      query: '',
    },
  };
  const search: any = location.search
    ? {
        ...defaultSearch,
        ...qs.parse(location.search),
      }
    : defaultSearch;

  search.sort = search.sort.length ? search.sort : [{ field: 'name', order: 'ASC' }];
  search.filter = search.filter || {};
  search.filter.isFavorite = true;

  yield put(favoriteActions.request({ search: search }));

  const response = yield call(getProducts, {
    ...search,
    pagination: { page: 0, size: 40 },
  });

  if (!response.errors) {
    yield put(favoriteActions.success({ search: search, favorites: response.data, isInfiniteLoad: false }));
  } else {
    yield put(favoriteActions.failure(response.errors));
  }
}

function* scrollFlow() {
  while (true) {
    yield take(ActionTypes.FETCH_MORE_FAVORITES);

    const location = yield select(makeSelectLocation());
    const defaultSearch = {
      sort: [],
      filter: {
        supplierNames: [],
        categoryName: '',
        query: '',
      },
    };

    const search: any = location.search ? { ...defaultSearch, ...qs.parse(location.search) } : defaultSearch;
    search.sort = search.sort.length ? search.sort : [{ field: 'name', order: 'DESC' }];
    search.filter = search.filter || {};
    search.filter.isFavorite = true;

    const scrollInfo = yield select(selectScrollInfo());
    const response = yield call(getProducts, { ...search, pagination: scrollInfo });
    if (!response.errors) {
      yield put(favoriteActions.success({ search: search, favorites: response.data, isInfiniteLoad: true }));
    } else {
      yield put(favoriteActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* favoritePageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initData);
  yield fork(searchFlow);
  yield fork(scrollFlow);
}
