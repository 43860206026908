/**
 *
 * FavoritePage
 *
 */

import { Modal } from 'antd';
import { Dispatch, compose } from 'redux';
import { applySearch, fetchMore } from './actions';
import {
  selectSelectedCategory,
  selectStore,
  selectUserPermissions,
  makeSelectLocation,
} from 'containers/MainLayout/selectors';

import Button from 'components/Button/Button';
import EmptyFavoriteImage from './EmptyFavorite.svg';
import FavoriteItem from './FavoriteItem';
import { FavoritePageHeader } from './FavoritePageHeader';
import { Filter } from './Filter/Filter';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalNote from 'components/Checkout/ModalNote';
import { Price } from 'components/MarketItem/Details/Price';
import QuantityInput from 'containers/QuantityInput';
import React from 'react';
import { RootState } from './types';
import { SupplierContainer } from './SupplierContainer';
import { Viewport } from 'components/ViewportProvider';
import { allowDecimalQuantity } from 'utils/allowDecimalQuantity';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import qs from 'qs';
import reducer from './reducer';
import saga from './saga';
import selectFavoritePage from './selectors';
import { setFavorite, setProductNote } from 'containers/MainLayout/actions';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import { Category, ProductNoteInput, TrackingAddToCartAddFromType } from 'types/schema';
import renderCategoryName from 'utils/renderCategoryName';
import media from 'utils/mediaStyle';
import KamereoLogo from '../../images/KamereoLogo.png';
import renderWithWebDomain from 'utils/renderWithWebDomain';

interface OwnProps {
  loading: boolean;
  favoritepage: any;
  store: any;
  category: Category;
  location: any;
  addedProducts: any;
  addingProducts: any;
  permissions: string[];
}

interface StateProps {}

interface DispatchProps {
  onToggleFavorite: (data: any) => void;
  setProductNote: (data: ProductNoteInput) => void;
  onSearch: (data: any) => void;
  fetchMore: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
  }
  img {
    max-width: 90%;
  }
`;

const PreviewImageAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
`;

const Container = styled.div`
  margin: 0px 16px 16px;
  ${media.md`
    margin: 0
  `};
`;

const addToCartPermission = 'ORDER_PLACE';
export class FavoritePage extends React.PureComponent<Props> {
  public state: { previewProduct: any; isOpenPreviewModal: boolean; isOpenNoteModal: boolean } = {
    previewProduct: null,
    isOpenPreviewModal: false,
    isOpenNoteModal: false,
  };

  public componentWillReceiveProps(nextProps) {
    const {
      favoritepage: { noteItemLoading },
    } = nextProps;

    if (this.state.isOpenNoteModal && !noteItemLoading && noteItemLoading !== this.props.favoritepage.noteItemLoading) {
      this.onCloseNote();
    }
  }

  public fetchMoreFavorites = () => {
    this.props.fetchMore();
  };

  public onToggleFavorite = (product) => () => {
    this.props.onToggleFavorite({
      product: product,
    });
  };

  public handleSelectSupplierChange = (selectedSuppliers) => {
    const { search } = this.props.favoritepage;
    this.props.onSearch({
      filter: {
        ...search.filter,
        supplierNames: selectedSuppliers.map((name) => name),
      },
    });
  };

  public onChangeSearch = (query) => {
    const { search } = this.props.favoritepage;
    this.props.onSearch({
      filter: {
        ...search.filter,
        query: query,
      },
    });
  };

  public onNoteSubmit = (note) => {
    const { previewProduct } = this.state;
    this.props.setProductNote({
      productId: previewProduct.id,
      note: note,
    });
    this.setState({
      previewProduct: {
        ...previewProduct,
        note: note,
      },
    });
  };

  public onOpenPreviewImage = (product) => () => {
    this.setState({
      previewProduct: product,
      isOpenPreviewModal: true,
    });
  };

  public onClosePreviewImage = () => {
    this.setState({
      previewProduct: null,
      isOpenPreviewModal: false,
    });
  };

  public onOpenNote = (product) => () => {
    this.setState({
      previewProduct: product,
      isOpenNoteModal: true,
    });
  };
  public onCloseNote = () => {
    this.setState({
      previewProduct: null,
      isOpenNoteModal: false,
    });
  };

  public render() {
    const {
      category,
      favoritepage: { suppliers, favoriteItems, search, loading, hasMoreFavorites, noteItemLoading },
      permissions,
      setProductNote,
    } = this.props;

    const marketSearch = { filter: { ...search.filter, isFavorite: undefined } };

    return (
      <Container>
        <Helmet>
          <title>{renderCategoryName(category, search, translations(messages.header))} | KAMEREO</title>
          <meta property="og:url" content={renderWithWebDomain(process.env.APP_ENV, 'favorite')} />
          <link rel="canonical" href={renderWithWebDomain(process.env.APP_ENV, 'favorite')} />
          <link rel="alternate" href={renderWithWebDomain(process.env.APP_ENV, 'favorite')} />
          <meta
            property="og:title"
            content={`${renderCategoryName(category, search, translations(messages.header))} | KAMEREO`}
          />
          <meta
            name="description"
            content={`${renderCategoryName(category, search, translations(messages.header))} | KAMEREO`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Kamereo" />
          <meta property="og:image:url" content={renderWithWebDomain(process.env.APP_ENV, KamereoLogo)} />
          <meta property="og:image:secure_url" content={renderWithWebDomain(process.env.APP_ENV, KamereoLogo)} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="768" />
          <meta property="og:image:height" content="768" />
          <meta property="og:image:alt" content="Kamereo Logo" />
          <meta name="robots" content={process.env.APP_ENV === 'production' ? 'index, follow' : 'noindex, nofollow'} />
        </Helmet>
        <FavoritePageHeader>{renderCategoryName(category, search, translations(messages.header))}</FavoritePageHeader>
        <Filter
          searchProduct={search.filter.query}
          selectedSuppliers={search.filter.supplierNames}
          selectableSuppliers={suppliers}
          onSearch={this.onChangeSearch}
          onChangeSupplier={this.handleSelectSupplierChange}
        />
        {suppliers.length === 0 && loading ? (
          <LoadingIndicator />
        ) : favoriteItems.length ? (
          <SupplierContainer>
            <Viewport.Consumer>
              {({ width }) => (
                <InfiniteScroll
                  dataLength={favoriteItems.length}
                  next={this.fetchMoreFavorites}
                  scrollThreshold="1000px"
                  hasMore={hasMoreFavorites}
                  loader={<LoadingIndicator />}
                >
                  {favoriteItems.map((item) => {
                    const { product, index } = item;
                    return (
                      <FavoriteItem
                        index={index}
                        key={product.id}
                        product={product}
                        onOpenNote={this.onOpenNote(product)}
                        setProductNote={setProductNote}
                        onToggleFavorite={this.onToggleFavorite(product)}
                        onOpenPreviewImage={this.onOpenPreviewImage(product)}
                      />
                    );
                  })}
                </InfiniteScroll>
              )}
            </Viewport.Consumer>
          </SupplierContainer>
        ) : (
          <EmptyContainer>
            <img src={EmptyFavoriteImage} alt="empty cart" />
            <p>
              <FormattedMessage {...messages.noFavotite} />
              <br />
              <FormattedMessage {...messages.gotoMartMessage} />
            </p>
            <Link to={`/market?${qs.stringify(marketSearch)}`}>
              <Button type="primary">
                <FormattedMessage {...utilsMessages.goToMart} />
              </Button>
            </Link>
          </EmptyContainer>
        )}
        <Modal
          footer={null}
          closable={false}
          width={320}
          bodyStyle={{ padding: 0, overflow: 'hidden', borderRadius: 4 }}
          onCancel={this.onClosePreviewImage}
          open={this.state.isOpenPreviewModal}
        >
          {!!this.state.previewProduct && (
            <div>
              <div style={{ position: 'relative' }}>
                <img
                  src={this.state.previewProduct.imageUrl || getImageByCategory(this.state.previewProduct)}
                  alt={this.state.previewProduct.name}
                  width={320}
                  height={320}
                />
                <p
                  style={{
                    padding: 8,
                    backgroundColor: 'rgba(43, 43, 43,.7)',
                    color: 'white',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    fontWeight: 'bold',
                  }}
                >
                  {this.state.previewProduct.name}
                </p>
              </div>
              <PreviewImageAction>
                <Price product={this.state.previewProduct} style={{ color: 'rgba(0,0,0,0.87)', marginBottom: 0 }} />
                {permissions.indexOf(addToCartPermission) > -1 && (
                  <QuantityInput
                    product={this.state.previewProduct}
                    size="small"
                    willOverflow
                    allowDecimal={allowDecimalQuantity(this.state.previewProduct.uom)}
                    addFrom={TrackingAddToCartAddFromType.FavoriteProduct}
                  />
                )}
              </PreviewImageAction>
            </div>
          )}
        </Modal>
        <ModalNote
          headerLabel={this.state.previewProduct ? this.state.previewProduct.name : ''}
          isOpen={this.state.isOpenNoteModal}
          confirmLoading={noteItemLoading}
          onSubmit={this.onNoteSubmit}
          onClose={this.onCloseNote}
          note={this.state.previewProduct ? this.state.previewProduct.note : ''}
        />
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  favoritepage: selectFavoritePage(),
  location: makeSelectLocation(),
  store: selectStore(),
  category: selectSelectedCategory(),
  permissions: selectUserPermissions(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onToggleFavorite: (data: any) => dispatch(setFavorite(data)),
    onSearch: (data: any) => dispatch(applySearch(data)),
    fetchMore: () => dispatch(fetchMore()),
    setProductNote: (data) => dispatch(setProductNote(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<OwnProps>({
  key: 'favoritePage',
  reducer: reducer,
});

const withSaga = injectSaga<OwnProps>({ key: 'favoritePage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(FavoritePage);
