/*
 *
 * FavoritePage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';
import { concat, flatMap, trim } from 'lodash';

import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';

export const initialState: ContainerState = {
  loading: true,
  favoriteItems: [],
  suppliers: [],
  suppliersMapping: {},
  search: {
    filter: {
      supplierNames: [],
      categoryName: '',
      query: '',
    },
  },
  scrollInfo: {
    page: 0,
    size: 40,
  },
  hasMoreFavorites: false,
  noteItemLoading: false,
};

export const renderFavorites = (favorites: any[], startIndex = 0) => {
  return flatMap(favorites, (product, index: number) => ({
    type: 'Product',
    product: product,
    index: index + startIndex,
  }));
};

export const concatFavorites = (accCollection: any[], currCollection: any[]) => {
  if (accCollection.length === 0 || currCollection.length === 0) {
    return accCollection;
  }

  return concat(accCollection, currCollection);
};

function favoritePageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_FAVORITE_SUCCESS:
      const startIndex = state.favoriteItems ? state.favoriteItems.length : 0;
      const favoriteItems = payload.isInfiniteLoad
        ? concatFavorites(state.favoriteItems, renderFavorites(payload.favorites, startIndex))
        : renderFavorites(payload.favorites);
      return {
        ...state,
        loading: false,
        favoriteItems: favoriteItems,
        search: payload.search,
        hasMoreFavorites: payload.favorites.length ? true : false,
        scrollInfo: {
          page: payload.isInfiniteLoad ? state.scrollInfo.page : 0,
          size: state.scrollInfo.size,
        },
      };
    case ActionTypes.FETCH_MORE_FAVORITES:
      return {
        ...state,
        scrollInfo: {
          page: state.scrollInfo.page + 1,
          size: state.scrollInfo.size,
        },
      };
    case ActionTypes.FETCH_FAVORITE_REQUEST:
      return {
        ...state,
        search: payload.search,
        loading: true,
        hasMoreFavorites: false,
      };
    case ActionTypes.FETCH_SUPPLIERS_SUCCESS:
      const suppliersMapping = payload.reduce((agg, supplier) => {
        agg[trim(supplier.name)] = supplier;
        return agg;
      }, {});
      return {
        ...state,
        suppliers: payload.map((s) => ({ name: trim(s.name) })),
        suppliersMapping: suppliersMapping,
      };
    case MainActionTypes.FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
        hasMoreFavorites: false,
      };
    case MainActionTypes.FAVORITE_SUCCESS:
      const productOptions = state.favoriteItems;
      const productIndex = productOptions.findIndex(
        (item) => item.type !== 'Supplier' && item.product.id === payload.id,
      );
      if (productIndex > -1) {
        productOptions.splice(productIndex, 1);
      }
      return {
        ...state,
        loading: false,
        hasMoreFavorites: true,
        favoriteItems: productOptions,
      };
    case MainActionTypes.SET_PRODUCT_NOTE_REQUEST:
      return {
        ...state,
        noteItemLoading: true,
      };
    case MainActionTypes.SET_PRODUCT_NOTE_SUCCESS:
      const newFavorite = state.favoriteItems.map((item) =>
        item.product && item.product.id === payload.id
          ? { ...item, product: { ...item.product, note: payload.note } }
          : item,
      );
      return { ...state, favoriteItems: newFavorite, noteItemLoading: false };
    case MainActionTypes.SET_PRODUCT_NOTE_FAILURE:
      return { ...state, noteItemLoading: false };
    default:
      return state;
  }
}

export default favoritePageReducer;
