import { Modal, Tag } from 'antd';
import { Price } from 'components/MarketItem/Details/Price';
import Favorite from 'components/MarketItem/Favorite';
import ProductNote, { NoteSm } from 'components/ProductNote';
import { Viewport } from 'components/ViewportProvider';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import QuantityInput from 'containers/QuantityInput';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import translations from 'translations';
import { Category, Product, ProductNoteInput, TrackingAddToCartAddFromType } from 'types/schema';
import { allowDecimalQuantity } from 'utils/allowDecimalQuantity';
import generateProductSlug from 'utils/generateProductSlug';
import utilsMessages from 'utils/messages';

const FavoriteItemDesktopContainer = styled.div`
  padding: 10px 8px;
  border-bottom: 1px solid #dce0e9;
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  align-items: center;
  font-size: 14px;
  &:hover {
    background-color: #fff;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    min-height: 124px;
    align-items: flex-start;
  }
`;

const FavoriteTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  a {
    color: rgba(0, 0, 0, 0.87);
  }
`;

const FavoriteActions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
  flex-basis: 16px;
`;

const FavoriteContent = styled.div`
  width: calc(100% - 90px);
  display: flex;
  flex-direction: column;
`;

const FavoritePrice = styled.div`
  margin: 4px 0;
  font-weight: 300;
  color: #595959;
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  &:hover {
    color: #40a9ff;
  }
`;

const componentPermisisons = {
  addToCartPermission: 'ORDER_PLACE',
  addFavoritePermission: 'BUYER_FAVORITE_ITEM_ADD',
};

interface FavoriteItemProps {
  permissions: string[];
  onOpenPreviewImage: () => void;
  onToggleFavorite: () => void;
  onOpenNote: () => void;
  setProductNote: (data: ProductNoteInput) => void;
  deleteNote: () => void;
  index: number;
  product: Product;
}

interface FavoriteItemWithTrackingProps {
  id: string;
  price: number;
  category?: Category | null;
}

const FavoriteItemWithTracking: FC<FavoriteItemWithTrackingProps> = (props) => {
  const { children } = props;

  return <FavoriteItemDesktopContainer>{children}</FavoriteItemDesktopContainer>;
};

const FavoriteItemMobile: FC<FavoriteItemProps> = (props: FavoriteItemProps) => {
  const { onOpenPreviewImage, onToggleFavorite, onOpenNote, deleteNote, permissions, product } = props;
  const { inStock, imageUrl, name, note, uom, category, supplierInternalCode } = product;
  const lang = localStorage.getItem('lang') || 'vi';
  return (
    <>
      <div style={{ position: 'relative' }}>
        <ImageContainer onClick={onOpenPreviewImage}>
          <img src={imageUrl || getImageByCategory(category)} alt={String(name)} width={80} height={80} />
        </ImageContainer>
        {permissions.indexOf(componentPermisisons.addFavoritePermission) > -1 && (
          <div style={{ position: 'absolute', left: 0, top: 80, transform: 'translateY(-50%)' }}>
            <Favorite favorite="true" position="static" onClick={onToggleFavorite} />
          </div>
        )}
      </div>
      <FavoriteContent>
        <div>
          <FavoriteTitle>
            <Link to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>{name}</Link>
          </FavoriteTitle>
          <FavoritePrice>
            <Price product={product} />
          </FavoritePrice>
          {note && <NoteSm>{note}</NoteSm>}
        </div>
        <FavoriteActions>
          <ProductNote isShowNote={false} note={note} onClickOpenNote={onOpenNote} onClickDeleteNote={deleteNote} />
          <div>
            {permissions.indexOf(componentPermisisons.addToCartPermission) > -1 &&
              (inStock ? (
                <QuantityInput
                  product={product}
                  size="small"
                  allowDecimal={allowDecimalQuantity(uom)}
                  addFrom={TrackingAddToCartAddFromType.FavoriteProduct}
                />
              ) : (
                <Tag
                  style={{
                    background: '#efefef',
                    border: 'none',
                    borderRadius: 50,
                    color: '#8c8c8c',
                    fontWeight: 500,
                    marginTop: 10,
                    marginRight: 0,
                    padding: '7px 12px',
                    float: 'right',
                    fontSize: 16,
                    width: 152,
                    textAlign: 'center',
                  }}
                >
                  <FormattedMessage {...utilsMessages.outStock} />
                </Tag>
              ))}
          </div>
        </FavoriteActions>
      </FavoriteContent>
    </>
  );
};

const StatusIconContainer = styled.div`
  margin-right: 8px;
`;
const ProductNameContainer = styled.div`
  margin-right: 8px;
  flex: 1;
`;
const ProductPriceContainer = styled.div`
  margin-right: 8px;
  width: 200px;
  text-transform: lowercase;
`;

const ActionContainer = styled.div`
  width: 150px;
  text-align: right;
  margin-right: 4px;
`;

const ImageContainer = styled.div`
  display: inline-block;
  margin-right: 8px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
`;

const FavoriteItemDesktop: FC<FavoriteItemProps> = (props: FavoriteItemProps) => {
  const { onOpenPreviewImage, onToggleFavorite, onOpenNote, deleteNote, permissions, product } = props;
  const { inStock, imageUrl, name, note, uom, category, supplierInternalCode } = product;
  const lang = localStorage.getItem('lang') || 'vi';
  return (
    <>
      <StatusIconContainer>
        {permissions.indexOf(componentPermisisons.addFavoritePermission) > -1 && (
          <Favorite favorite="true" position="relative" alignto="auto" onClick={onToggleFavorite} />
        )}
      </StatusIconContainer>
      <ImageContainer onClick={onOpenPreviewImage}>
        <img src={imageUrl || getImageByCategory(category)} alt={String(name)} width={80} height={80} />
      </ImageContainer>
      <ProductNameContainer>
        <StyledLink to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>{name}</StyledLink>
        <ProductNote note={note} onClickOpenNote={onOpenNote} onClickDeleteNote={deleteNote} />
      </ProductNameContainer>
      <ProductPriceContainer>
        <Price product={product} />
      </ProductPriceContainer>
      <ActionContainer>
        {permissions.indexOf(componentPermisisons.addToCartPermission) > -1 &&
          (inStock ? (
            <QuantityInput
              product={product}
              size="small"
              allowDecimal={allowDecimalQuantity(uom)}
              addFrom={TrackingAddToCartAddFromType.FavoriteProduct}
            />
          ) : (
            <Tag
              style={{
                background: '#efefef',
                border: 'none',
                borderRadius: 50,
                color: '#8c8c8c',
                fontWeight: 500,
                marginTop: 10,
                marginRight: 0,
                padding: '7px 12px',
                float: 'right',
                fontSize: 16,
                width: 152,
                textAlign: 'center',
              }}
            >
              <FormattedMessage {...utilsMessages.outStock} />
            </Tag>
          ))}
      </ActionContainer>
    </>
  );
};

interface RootState {}
interface StateProps {}

const FavoriteItem: FC<FavoriteItemProps> = (props: FavoriteItemProps) => {
  const { setProductNote, product } = props;
  const { id, price, category } = product;
  const deleteNote = useCallback(() => {
    Modal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: translations(utilsMessages.titleRemoveNote),
      content: <p style={{ fontWeight: 300, marginTop: 16 }}>*{translations(utilsMessages.noteRemoveNote)}</p>,
      okText: translations(utilsMessages.yes),
      cancelText: translations(utilsMessages.no),
      onOk: () => {
        setProductNote({
          productId: id,
          note: '',
        });
      },
    });
  }, []);

  return (
    <Viewport.Consumer>
      {({ width }) => (
        <FavoriteItemWithTracking id={id} price={price} category={category}>
          {width < 1025 ? (
            <FavoriteItemMobile {...props} deleteNote={deleteNote} />
          ) : (
            <FavoriteItemDesktop {...props} deleteNote={deleteNote} />
          )}
        </FavoriteItemWithTracking>
      )}
    </Viewport.Consumer>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const added = stateProps.addedProducts[ownProps.id];
  const adding = stateProps.addingProducts[ownProps.id];
  return {
    ...ownProps,
    added: added,
    adding: adding,
    permissions: stateProps.permissions,
  };
};

const withConnect = connect(mapStateToProps, null, mergeProps);

export default withConnect(FavoriteItem);
