/*
 *
 * FavoritePage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/FavoritePage/DEFAULT_ACTION',
  APPLY_SEARCH = 'app/FavoritePage/APPLY_SEARCH',
  FETCH_SUPPLIERS_REQUEST = 'app/FavoritePage/FETCH_SUPPLIERS_REQUEST',
  FETCH_SUPPLIERS_SUCCESS = 'app/FavoritePage/FETCH_SUPPLIERS_SUCCESS',
  FETCH_SUPPLIERS_FAILURE = 'app/FavoritePage/FETCH_SUPPLIERS_FAILURE',
  FETCH_FAVORITE_REQUEST = 'app/FavoritePage/FETCH_FAVORITE_REQUEST',
  FETCH_FAVORITE_SUCCESS = 'app/FavoritePage/FETCH_FAVORITE_SUCCESS',
  FETCH_FAVORITE_FAILURE = 'app/FavoritePage/FETCH_FAVORITE_FAILURE',
  FETCH_MORE_FAVORITES = 'app/FavoritePage/FETCH_MORE_FAVORITES',
}

export default ActionTypes;
