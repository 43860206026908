import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the favoritePage state domain
 */

const selectFavoritePageDomain = (state: ApplicationRootState) => {
  return state ? state.favoritePage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by FavoritePage
 */

const selectFavoritePage = () =>
  createSelector(selectFavoritePageDomain, substate => {
    return substate;
  });

const selectSearch = () =>
  createSelector(selectFavoritePageDomain, substate => {
    return substate.search;
  });

const selectScrollInfo = () =>
  createSelector(selectFavoritePageDomain, substate => {
    return substate.scrollInfo;
  });

const selectSuppliers = () =>
  createSelector(selectFavoritePageDomain, substate => {
    return substate.suppliers;
  });

export default selectFavoritePage;
export { selectFavoritePageDomain, selectSearch, selectScrollInfo, selectSuppliers };
